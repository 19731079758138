import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Modal, Popover, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";
import {updateVictories} from "../../ForceConnector";
import {notificationVictoriesUpdateSuccess} from "../../ForceNotifications";
import {notificationFailure} from "../../../card/CardNotifications";

const Victories = ({value, forceId}) => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        doRestCall('/crusade/force/' + forceId + '/victories', 'get', null, null,
            (response) => {
                setItems(response.body.items);
            })
    }, [forceId]);

    const onVictoriesUpdate = (e) => {
        updateVictories(forceId, e.target.value, notificationVictoriesUpdateSuccess, notificationFailure)
    }


    function header() {
        return <h4>Games</h4>;
    }


    function content(items) {
        return <div>
            <div>&nbsp;</div>
            <div>
                {items?.map(g => <div>{g.gameName}</div>)}
            </div>
            <div>&nbsp;</div>
        </div>;
    }

    return <>
        <Popover content={content(items)} title={header()}>
            <input type="number" min={0} defaultValue={value} disabled={true} onBlur={(e) => {
                    onVictoriesUpdate(e)
                }}/>
        </Popover>
    </>
}

export default Victories;


