import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Form, Input, Modal, Popconfirm, Select, Space, Spin, Table, Tabs} from "antd";
import {doRestCall} from "../../../AppUtils";
import Winner from "./Winner";
import moment from "moment";
import {DeleteOutlined} from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import ParticipantCheck from "../../view/components/ParticipantCheck";

const RoundDetails = ({item, deleteGame}) => {

    return <>
        <tr className="t-e-vtable-row">
            <td className="t-e-vtable-cell">{item.name}</td>
            <td className="t-e-vtable-cell">{item.player1}</td>
            <td className="t-e-vtable-cell">{item.player2}</td>
            <td className="t-e-vtable-cell"><Winner players={item.players} winner={item.winner}
                                                    gameId={item.id}/></td>
            <td className="t-e-vtable-cell">{item.date && moment(item.date, 'YYYY-MM-DDThh:mm:ss').format('DD MMM YYYY')}</td>
            <td className="t-e-vtable-cell"><i className="fa-solid fa-trash"
                                               onClick={() => deleteGame(item.id)}></i>
            </td>
        </tr>
    </>
}

export default RoundDetails;