import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";
import WarlordMark from "./WarlordMark";
import Weapons from "./Weapons";
import Models from "./Models";

const Unit = ({rosterId, unit, setSummary, reloadRoster}) => {

    const [selected, setSelected] = useState(false)
    const [warlord, setWarlord] = useState(false)

    useEffect(() => {
        setSelected(unit.selected)
        setWarlord(unit.warlord)
    }, [unit])

    return (
        <>
            <div>
                <div className="r-view-unit-name"><WarlordMark warlord={warlord} /> {unit?.name}</div>
                <Models rosterId={rosterId} unitId={unit.id} isSingleModelUnit={unit.isSingleModelUnit} models={unit.models} />
                <Weapons rosterId={rosterId} unitId={unit.id} weapons={unit.weapons} />
            </div>
        </>
    );
};

export default Unit;

