import React, {useEffect, useState} from 'react';
import {Form, List, Select, Space, Spin, Typography} from 'antd';
import {doRestCall} from "../../../AppUtils";

const {Text, Link} = Typography;

const Master = ({master, tournamentId}) => {

    const [users, setUsers] = useState({})
    const [loading, setLoading] = useState(false);
    const [selectedMaster, setSelectedMaster] = useState({});

    useEffect(() => {
        setSelectedMaster(master)
    }, [master])

    useEffect(() => {
        setLoading(true)
        doRestCall('/tournament/users', 'get', null, null, onAllUsersSuccess, null,
            () => setLoading(false))
    }, [])

    function onAllUsersSuccess(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
                key: response.body[i].key,
                faction: response.body[i].faction
            }
        }
        setUsers(response.body)
    }

    function onTournamentMasterSelect(userId) {
        setSelectedMaster(userId)
        doRestCall('/tournament/update/master', 'put', null, {
            tournamentId: tournamentId,
            userId: userId
        })
    }

    return <>
            <Select
                value={selectedMaster}
                options={users}
                onSelect={onTournamentMasterSelect}
                className="select"/>
    </>
}

export default Master;