import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input, InputNumber,
    Select,
    Space,
    Table, Typography
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import moment from "moment";

import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Universes from "./components/Universes";
import {doRestCall} from "../../AppUtils";
import Rounds from "./components/Rounds";
import Participants from "./components/Participants";
import Master from "./components/Master";


import "./styles-t-edit.css"

const { TextArea } = Input;
const { Text } = Typography;

const TournamentEdit = () => {

    GoogleAnalyticsPageView("/tournament/edit", "Tournament Edit")

    const navigate = useNavigate();
    let {tournamentId} = useParams();

    const [gamesLoading, setGamesLoading] = useState(true)
    const [games, setGames] = useState([]);

    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});

    const [selectedUniverse, setSelectedUniverse] = useState()

    const [errors, setErrors] = useState(undefined)

    useEffect(() => {
        loadGamesEffect()
        loadTournament();
    }, [])

    function onViewSuccess(response) {
        setTournament(response.body)
        setLoading(false)
    }

    const loadTournament = () => {
        doRestCall( '/tournament/view/' + tournamentId, 'get', null, null, onViewSuccess)
    }

    function onAllGamesSuccess(response) {

        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].name,
                value: response.body[i].id,
                label: response.body[i].name,
                key: response.body[i].key
            }
        }

        setGames(response.body)
        setGamesLoading(false)
    }

    const loadGamesEffect = () => {
        doRestCall( '/tournament/games', 'get', null, null, onAllGamesSuccess)
    }

    function navigateBack() {
        navigate("/tournament/view/" + tournamentId)
    }
    return (
        <>
            <Auth/>

            <div className="t-e-create-game-section mt-5">
                <div className="t-e-create-game-container">
                    <div className="t-e-create-game-header mb-5">
                        <h2 className="t-e-title2">Edit Tournament</h2>
                    </div>
                    <div className="t-e-create-game-form">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12">
                                <div className="form mb-5">
                                    <div className="t-e-mb-3">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Name</label></div>
                                            <div className="col-md-9">
                                                <input type="text" placeholder="Name" defaultValue={tournament.name}
                                                       onChange={(event) => {
                                                           doRestCall('/tournament/update/name', 'put', null, {
                                                               tournamentId: tournament.id,
                                                               name: event.target.value
                                                           })
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row t-e-mb-3">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">Start Date</label></div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.startDate && moment(tournament.startDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/startdate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   startDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">Registration Start</label></div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.registrationStartDate && moment(tournament.registrationStartDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/regstartdate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   regStartDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row t-e-mb-3">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">End Date</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.endDate && moment(tournament.endDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/enddate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   endDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">Registration End</label></div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.registrationEndDate && moment(tournament.registrationEndDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/regenddate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   regEndDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="t-e-mb-3">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Universe</label></div>
                                            <div className="col-md-9">
                                                <Universes
                                                    tournamentId={tournament.id}
                                                    setSelectedUniverse={setSelectedUniverse}
                                                    preSelectedUniverse={tournament.universe}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="t-e-mb-3">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Tournament Master</label></div>
                                            <div className="col-md-9">
                                                <Master tournamentId={tournamentId} master={tournament.tournamentMasterId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Participants Limit</label></div>
                                            <div className="col-md-9">
                                                <input type="text" placeholder="Limit"
                                                          defaultValue={tournament.participantsLimit}
                                                          onBlur={(data) => {
                                                              doRestCall('/tournament/update/participantslimit', 'put', null, {
                                                                  tournamentId: tournament.id,
                                                                  participantsLimit: data.target.value
                                                              })
                                                          }}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Notes</label></div>
                                            <div className="col-md-9">
                                                <textarea name="" id="" className="form-control" rows="4"
                                                          defaultValue={tournament.notes}
                                                          onBlur={(data) => {
                                                              doRestCall('/tournament/update/notes', 'put', null, {
                                                                  tournamentId: tournament.id,
                                                                  notes: data.target.value
                                                              })
                                                          }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <Participants tournamentId={tournament.id}
                                                  tournamentPlayers={tournament.players}
                                                  selectedUniverse={selectedUniverse} />

                                    <Rounds
                                        tournamentId={tournamentId}
                                        rounds={tournament.rounds}
                                        setErrors={setErrors}
                                        setTournament={setTournament}/>

                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                        <div className="t-e-tlist-btn" onClick={() => navigateBack()}>Back</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TournamentEdit;

