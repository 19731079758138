import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Models = ({rosterId, unitId, isSingleModelUnit, models}) => {

    function clicked(e) {
        e.preventDefault()

            doRestCall('/roster/unit/warlord',
                'put', null, {
                    rosterId: rosterId,
                    unitId: unitId,
                    value: true
                })
    }

    /*<input type="number" placeholder="" min={0} defaultValue={card?.points} onBlur={(e) => onPointsUpdate(e)}*/
    return (
        <>
            {!isSingleModelUnit && models?.map(model =>
                <div className="r-manage-unit-model-name">
                    <input className="r-manage-input-number" type="number" placeholder="" min={0} />
                    {model?.name}
                </div>)
            }
        </>
    );
};

export default Models;

