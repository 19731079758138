import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input, InputNumber, notification, Popover,
    Select,
    Table
} from "antd";
import {useNavigate, useParams} from "react-router-dom";

import {
    calculateUnitPrice,
    updateBattleTraits,
    updateBattleScars,
    updateBattlesPlayed,
    updateBattlesSurvived,
    updateCrusadePoints,
    updateEnemyUnitsDestroyed,
    updateEnhancements,
    updateEquipment,
    updateExperiencePoints, updateFaction,
    updateKeywords,
    updateName,
    updatePoints, updateRank,
    updateType, updateUnit, updateUnitName,
    viewCard
} from "../CardConnector";
import {Auth} from "../../../user/auth/Auth";
import TextArea from "antd/es/input/TextArea";
import {
    notificationBattleTraitsUpdateSuccess,
    notificationBattleScarsUpdateSuccess,
    notificationBattlesPlayedUpdateSuccess,
    notificationBattlesSurvivedUpdateSuccess,
    notificationCrusadePointsUpdateSuccess,
    notificationEnemyUnitsDestroyedUpdateSuccess,
    notificationEnhancementsUpdateSuccess,
    notificationEquipmentsUpdateSuccess,
    notificationExperiencePointsUpdateSuccess,
    notificationFactionUpdateSuccess,
    notificationFailure,
    notificationKeywordsUpdateSuccess,
    notificationNameUpdateSuccess, notificationNotesUpdateSuccess,
    notificationPointsUpdateSuccess,
    notificationRankUpdateSuccess,
    notificationTypeUpdateSuccess, notificationUnitNameUpdateSuccess, notificationUnitUpdateSuccess
} from "../CardNotifications";
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import Factions from "./components/Factions";
import Units from "./components/Units";
import {doRestCall} from "../../../AppUtils";

import "./c-c-manage-styles.css"
import BattleScars from "./components/BattleScars";
import BattleTraits from "./components/BattleTraits";
import WeaponModifications from "./components/WeaponModifications";
import Relics from "./components/Relics";
import ExperiencePoints from "./components/ExperiencePoints";


const CardManage = () => {

    GoogleAnalyticsPageView("/crusade/card/manage", "Crusade Card Manage")

    const navigate = useNavigate();

    let {forceId, cardId} = useParams();

    const [card, setCard] = useState();

    useEffect(() => {
        load()
    }, []);

    const load = () => {
        doRestCall( '/crusade/force/' + forceId + '/card/' + cardId + '/view', 'get', null, null,
            (response) => {
                if (response.valid) {
                    setCard(response.body)
                }
            })
    }

    const calculatePoints = (unit) => {
        calculateUnitPrice(unit)
            .then(response => {
                    if (response.valid) {
                        card.points = response.body
/*
                        form.setFieldsValue({
                            points: card.points
                        })
*/
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function navigateBack(e) {
        navigate("/crusade/force/" + forceId + "/manage")
    }

    const onNameUpdate = (e) => {
        updateName(forceId, cardId, e.target.value, notificationNameUpdateSuccess, notificationFailure)
    }
    const onUnitUpdate = (e) => {
        updateUnit(forceId, cardId, e.target.value, notificationUnitUpdateSuccess, notificationFailure)
/*
        calculatePoints(value)
*/
    }
    const onFactionUpdate = (e) => {
        updateFaction(forceId, cardId, e.target.value, notificationFactionUpdateSuccess, notificationFailure)
    }
    const onKeywordsUpdate = (e) => {
        doRestCall('/crusade/card/update/keywords', 'put', {
            forceId: forceId,
                cardId: cardId,
                keywords: e.target.value
        }, null,
            (response) => {
                notificationKeywordsUpdateSuccess()
                setCard(response.body)
            },
            () => {
                notificationFailure()
            })
    }
    const onEquipmentsUpdate = (e) => {
        updateEquipment(forceId, cardId, e.target.value, notificationEquipmentsUpdateSuccess, notificationFailure)
    }
    const onEnhancementsUpdate = (e) => {
        updateEnhancements(forceId, cardId, e.target.value, notificationEnhancementsUpdateSuccess, notificationFailure)
    }
    const onPointsUpdate = (e) => {
        updatePoints(forceId, cardId, e.target.value, notificationPointsUpdateSuccess, notificationFailure)
    }
    const onCrusadePointsUpdate = (e) => {
        updateCrusadePoints(forceId, cardId, e.target.value, notificationCrusadePointsUpdateSuccess, notificationFailure)
    }

    const onRankUpdate = (e) => {
        updateRank(forceId, cardId, e.target.value, notificationRankUpdateSuccess, notificationFailure)
    }
    const onBattlesPlayedUpdate = (e) => {
        updateBattlesPlayed(forceId, cardId, e.target.value, notificationBattlesPlayedUpdateSuccess, notificationFailure)
    }
    const onBattlesSurvivedUpdate = (e) => {
        updateBattlesSurvived(forceId, cardId, e.target.value, notificationBattlesSurvivedUpdateSuccess, notificationFailure)
    }
    const onEnemyUnitsDestroyedUpdate = (e) => {
        updateEnemyUnitsDestroyed(forceId, cardId, e.target.value, notificationEnemyUnitsDestroyedUpdateSuccess, notificationFailure)
    }
    const onBattleTraitsUpdate = (e) => {
        updateBattleTraits(forceId, cardId, e.target.value, notificationBattleTraitsUpdateSuccess, notificationFailure)
    }

    const onNotesUpdate = (e) => {
        doRestCall( '/crusade/card/update/notes', 'put', {
            forceId: forceId,
            cardId: cardId,
            notes: e.target.value
        }, null, notificationNotesUpdateSuccess, notificationFailure)
    }

    return (
        <>
            <Auth/>

            <div className="c-c-manage-create-game-section mt-5">
                <div className="c-c-manage-create-game-container">
                    <div className="c-c-manage-create-game-header mb-5">
                        <h2>Manage Crusade Card</h2>
                    </div>
                    <div className="c-c-manage-create-game-form">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8">
                                <div className="form mb-5">
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Name</label></div>
                                            <div className="col-md-8"><input type="text" placeholder="" defaultValue={card?.name} onBlur={(e) => onNameUpdate(e) } /></div>
                                        </div>
                                    </div>
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Faction</label></div>
                                            <div className="col-md-8"><input type="text" placeholder="" defaultValue={card?.faction} onBlur={(e) => onFactionUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                    <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Unit</label></div>
                                            <div className="col-md-8"><input type="text" placeholder="" defaultValue={card?.unit} onBlur={(e) => onUnitUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Keywords</label></div>
                                            <div className="col-md-8">
                                                <textarea name="" id="" className="form-control" rows="4" defaultValue={card?.keywords} onBlur={(e) => onKeywordsUpdate(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Equipments</label></div>
                                            <div className="col-md-8">
                                                <textarea name="" id="" className="form-control" rows="4" defaultValue={card?.equipments} onBlur={(e) => onEquipmentsUpdate(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Enhancements</label></div>
                                            <div className="col-md-8">
                                                <textarea name="" id="" className="form-control" rows="4" defaultValue={card?.enhancements} onBlur={(e) => onEnhancementsUpdate(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Points</label></div>
                                            <div className="col-md-8"><input type="number" placeholder="" min={0} defaultValue={card?.points} onBlur={(e) => onPointsUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label className="form-label">Crusade Points</label></div>
                                            <div className="col-md-8"><input type="number" placeholder=""  min={0} defaultValue={card?.crusadePoints} onBlur={(e) => onCrusadePointsUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <ExperiencePoints value={card?.experiencePoints} forceId={forceId} cardId={cardId} />
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label
                                                className="form-label">Rank</label></div>
                                            <div className="col-md-8"><input type="text" placeholder="" defaultValue={card?.rank} onBlur={(e) => onRankUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label className="form-label">Battles
                                                Played</label></div>
                                            <div className="col-md-8"><input type="number" placeholder="" defaultValue={card?.battlesPlayed} onBlur={(e) => onBattlesPlayedUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label className="form-label">Battles
                                                Survived</label></div>
                                            <div className="col-md-8"><input type="number" placeholder="" defaultValue={card?.battlesSurvived} onBlur={(e) => onBattlesSurvivedUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <div className="c-c-manage-mb-3">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label className="form-label">Enemy Units
                                                Destroyed</label></div>
                                            <div className="col-md-8"><input type="number" placeholder="" defaultValue={card?.enemyUnitsDestroyed} onBlur={(e) => onEnemyUnitsDestroyedUpdate(e)}/></div>
                                        </div>
                                    </div>
                                    <BattleTraits cardId={cardId} forceId={forceId} card={card} />
                                    <WeaponModifications cardId={cardId} forceId={forceId} card={card} />
                                    <Relics cardId={cardId} forceId={forceId} card={card} />
                                    <BattleScars cardId={cardId} forceId={forceId} battleScars={card?.battleScars} />
                                      <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-4 text-end"><label className="form-label">Notes</label></div>
                                            <div className="col-md-8">
                                                <textarea name="" id="" className="form-control" rows="4" defaultValue={card?.notes} onBlur={(e) => onNotesUpdate(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                        <div className="c-c-manage-tlist-btn" onClick={(e) => navigateBack(e) }>Back</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardManage;

