import {doRestCall} from "../../../AppUtils";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {Popconfirm} from "antd";

const DeleteButton = ({currentUser, gameId, ownerId, gameMasterId}) => {

    const navigate = useNavigate();


    function onConfirmDelete() {
        doRestCall('/game/delete/' + gameId, 'delete', null, null, onSuccess)
    }

    function onSuccess() {
        navigate('/games')
    }

    return (
        <>
            {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId || gameMasterId) &&
                <span className="g-v-popconfirm">
                    <Popconfirm
                        title={"Delete game"}
                        description={"Are you sure you want to delete this game?"}
                        onConfirm={onConfirmDelete}>
                        <button className="g-v-tlist-btn-active">Delete</button>
                    </Popconfirm>
                </span>
            }
        </>
    );
};

export default DeleteButton;