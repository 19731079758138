import React, {useEffect, useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

import './styles-r-all.css'
import Spinner from "../../_components/Spinner";
import Pagination from "../../home/components/Pagination";
import {Modal, Space} from "antd";

const Rosters = () => {

    GoogleAnalyticsPageView("/rosters", "Rosters")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [rosters, setRosters] = useState([]);

    const [selectedPage, setSelectedPage] = useState(1)
    const [pagination, setPagination] = useState({})

    const [isModalOpen, setModalOpen] = useState(false)
    const [newRosterName, setNewRosterName] = useState("")
    const [newRosterError, setNewRosterError] = useState()


    function onSuccess(response) {
        setRosters(response.body)
        setPagination(response.pagination)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        doRestCall( '/rosters', 'get', {
                currentPage: selectedPage
            }, null, onSuccess, null,
            () => setLoading(false))
    }, [selectedPage])

    function view(id) {
        navigate('/roster/' + id + '/view')
    }

    function onCreate(e) {
        e.preventDefault()
        setModalOpen(true)
    }

    function onOk() {
        if (newRosterName === null || newRosterName === undefined || newRosterName === "") {
            setNewRosterError("Name is required")
            return
        }

        doRestCall('/roster/create', 'post', null, {
            name: newRosterName
        }, (response) => {
            setModalOpen(false)
            navigate("/roster/" + response.body.id + "/manage")
        })
    }

    function onCancel() {
        setNewRosterError("")
        setNewRosterName("")
        setModalOpen(false)
    }

    return (
        <>
            <Auth />

            <Modal title="Create Roster" open={isModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                {newRosterError && <p>{newRosterError}</p>}
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" placeholder="Name" onChange={(e) => setNewRosterName(e.target.value)}/>
                </Space>
            </Modal>

            <Spinner isLoading={loading}>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-11">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-auto">
                            </div>
                            <div className="col-auto">
                                <button className="r-all-tlist-btn" onClick={(e) => onCreate(e)}>Create Roster</button>>
                            </div>
                        </div>

                        <div className="r-all-vtable-container">
                            <table className="r-all-vtable">
                                <thead className="r-all-vtable-head">
                                <tr className="r-all-vtable-row">
                                    <th className="r-all-vtable-header">Name</th>
                                </tr>
                                </thead>
                                <tbody className="r-all-vtable-body">
                                {rosters?.map(roster =>
                                    <tr className="r-all-vtable-row">
                                        <td className="r-all-vtable-cell r-all-vtable-cell-name"><a
                                            onClick={() => view(roster.id)}>{roster.name}</a></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <Pagination pagination={pagination} selectedPage={setSelectedPage} />
                        </div>
                    </div>
                </div>
            </Spinner>
        </>
    );
};

export default Rosters;

