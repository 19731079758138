import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const CrusadeGameResults = ({gameId, userId, rosterId}) => {

    const [settings, setSettings] = useState([])

    const [isCrusadeSelected, setCrusadeSelected] = useState(false)
    const [gameResults, setGameResults] = useState({})

    function load() {
        doRestCall('/crusade/roster/' + rosterId + '/game/' + gameId + '/gameresults', 'get', null, null,
            (response) => {
                setGameResults(response.body)
            })
    }

    useEffect(() => {
        if (gameId !== null && gameId !== undefined && rosterId !== null && rosterId !== undefined) {
            load();
        }
    }, [gameId, rosterId])

    useEffect(() => {

        if (gameId !== null && gameId !== undefined) {
            doRestCall('/game/' + gameId + '/settings', 'get', null, null,
                (response) => setSettings(response.body))
        }
    }, [gameId])

    useEffect(() => {
        if (settings !== null && settings !== undefined) {
            setCrusadeSelected(settings.filter(setting => setting.selected === true)
                .map(setting => setting.name)
                .includes('CRUSADE'))
        }
    }, [settings])


    function survived(e, isSurvived, gameResultId, cardId) {
        e.preventDefault()

        doRestCall('/crusade/roster/gameresults/card/survived', 'post', null, {
                gameResultId: gameResultId,
                cardId: cardId,
                gameId: gameId,
                rosterId: rosterId,
                isSurvived: isSurvived
            },
            (response) => load())

    }

    function markOfGreatness(e, markOfGreatness, gameResultId, cardId) {
        e.preventDefault()

        doRestCall('/crusade/roster/gameresults/card/markofgreatness', 'post', null, {
                gameResultId: gameResultId,
                cardId: cardId,
                gameId: gameId,
                rosterId: rosterId,
                markOfGreatness: markOfGreatness
            },
            (response) => load())

    }

    function enemyUnitsDestroyed(e, gameResultId, cardId) {
        e.preventDefault()

        doRestCall('/crusade/roster/gameresults/card/enemyunitsdestroyed', 'post', null, {
                gameResultId: gameResultId,
                cardId: cardId,
                gameId: gameId,
                rosterId: rosterId,
                enemyUnitsDestroyed: e.target.value
            },
            (response) => load())

    }

    if (!isCrusadeSelected) {
        return (
            <>

            </>
        );
    }

    if (isCrusadeSelected) {
        return (
            <>
                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                    <div className="col-auto">
                        <div className="g-v-gd-bold">Game Results</div>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                    <div className="col-auto">
                        <div className="g-v-gd-info">

                            <div className="g-all-vtable-container">
                                <table className="g-all-vtable">
                                    <thead className="g-all-vtable-head">
                                    <tr className="g-all-vtable-row">
                                        <th className="g-all-vtable-header">Name</th>
                                        <th className="g-all-vtable-header">Survived</th>
                                        <th className="g-all-vtable-header">Enemy Units Destroyed</th>
                                        <th className="g-all-vtable-header">Mark of Greatness</th>
                                    </tr>
                                    </thead>
                                    <tbody className="g-all-vtable-body">
                                    {gameResults?.cards?.map(card =>
                                        <tr className="g-all-vtable-row">
                                            <td className="g-all-vtable-cell g-all-vtable-cell-name">{card.cardName}</td>
                                            <td className="g-all-vtable-cell">
                                                {card.survived && <i className="fa fa-check"
                                                                     onClick={(e) => survived(e, false,  card.gameResultId, card.cardId)}></i>}
                                                {!card.survived && <i className="fa-regular fa-square"
                                                                      onClick={(e) => survived(e, true,  card.gameResultId, card.cardId)}></i>}
                                            </td>
                                            <td className="g-all-vtable-cell "><input type="number" min={0}
                                                                                      defaultValue={card.enemyUnitsDestroyed}
                                                                                      onBlur={(e) => enemyUnitsDestroyed(e,  card.gameResultId, card.cardId)}/>
                                            </td>
                                            <td className="g-all-vtable-cell ">
                                                {card.markOfGreatness &&
                                                    <i className="fa fa-check"
                                                       onClick={(e) => markOfGreatness(e, false, card.gameResultId, card.cardId)}></i>}
                                                {!card.markOfGreatness &&
                                                    <i className="fa-regular fa-square"
                                                       onClick={(e) => markOfGreatness(e, true, card.gameResultId, card.cardId)}></i>}
                                            </td>

                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>

            </>
        );
    }

};

export default CrusadeGameResults;