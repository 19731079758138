import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";

const Factions = ({universe, setSelectedFaction}) => {
    const [factionOptions, setFactionOptions] = useState([])
    const [factions, setFactions] = useState([]);
    const navigate = useNavigate();

    const [tournamentUniverse, setTournamentUniverse] = useState(universe)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }
    }, [factions]);

    useEffect(() => {
        if (tournamentUniverse !== undefined && tournamentUniverse !== null) {
            setLoading(true)

            doRestCall('/codex/factions', 'get', {
                    universe: tournamentUniverse,
                }, null,
                (response) => setFactions(response.body),
                null,
                () => setLoading(false))
        }
    }, [tournamentUniverse]);

    const onFactionChange = (value) => {
        setSelectedFaction(value)
    };

    return <>
        <Spinner isLoading={loading}>
            <Select
                showSearch
                placeholder="Select faction"
                onChange={onFactionChange}
                options={factionOptions}
                style={{width: 200}}
            />
        </Spinner>
    </>
}

export default Factions;