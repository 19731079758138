import React, {useEffect, useState} from 'react';
import {Form, Select, Spin, Steps} from 'antd';
import {doRestCall} from "../../../AppUtils";

const ParticipantCheck = ({user, tournamentId}) => {

    const [selected, setSelected] = useState(user.selected)

    function update(user) {
        let state = !user.selected;
        doRestCall('/tournament/update/participant', 'put', null, {
            tournamentId: tournamentId,
            participantId: user.id,
            state: state
        })
        setSelected(state)

    }

    useEffect(() => {
        setSelected(user.selected)
    }, [user.selected])

    return <>
        {selected ?
            <i className="fa-solid fa-check" onClick={() => update(user)}></i> :
            <i className="fa-regular fa-square" onClick={() => update(user)}></i>}
    </>
}

export default ParticipantCheck;