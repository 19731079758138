import React from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";

const DeleteButton = ({rosterId, ownerId}) => {

    const {currentUser} = useCurrentUserContext();
    const navigate = useNavigate();


    return <>
        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId)
            && <button className="r-view--tlist-btn-active" >Delete</button>}
    </>
}
export default DeleteButton;