import React from "react";
import {useNavigate} from "react-router-dom";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";

const EditButton = ({currentUser, gameId, ownerId, gameMasterId}) => {

    const navigate = useNavigate();

    const onEdit = () => {
        navigate("/game/edit/" + gameId)
    };

    return (
        <>
            {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId || gameMasterId) &&
                <button className="g-v-tlist-btn" onClick={onEdit}>Edit</button>
            }
        </>
    );
};

export default EditButton;