import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Weapons = ({rosterId, unitId, weapons}) => {

    return (
        <>
            {weapons.filter(weapon => weapon.type === 'RANGE').length > 0 &&
                <div>
                    <div className="r-view-unit-weapon-type">Ranged</div>
                    {weapons.filter(weapon => weapon.type === 'RANGE')
                        .map(weapon => <div>
                            <div className="r-view-unit-weapon-name">{weapon.count} x {weapon.name}</div>
                            {weapon?.profiles?.map(profile => <div>{profile.name}</div>)}
                        </div>)}
                </div>
            }
            {weapons.filter(weapon => weapon.type === 'MELEE').length > 0 &&
                <div>
                    <div className="r-view-unit-weapon-type">Melee</div>
                    {weapons.filter(weapon => weapon.type === 'MELEE')
                        .map(weapon => <div>
                            <div className="r-view-unit-weapon-name">{weapon.count} x {weapon.name}</div>
                            {weapon?.profiles?.map(profile => <div>{profile.name}</div>)}
                        </div>)}
                </div>
            }
        </>
    );
};

export default Weapons;

