import React, {useEffect, useState} from "react";
import WarlordMark from "./WarlordMark";

const Card = ({card}) => {
    return (
        <>
            <div>
                <WarlordMark warlord={card.warlord} />
                 {card?.name} {/*<i className="fa-solid fa-circle-exclamation"></i>*/}
                <div className="c-r-manage-equipments" >{card?.equipments}</div>
            </div>
        </>
    );
};

export default Card;

