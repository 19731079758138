import React, {useEffect, useState} from "react";
import WarlordButton from "./WarlordButton";
import {doRestCall} from "../../../../AppUtils";
import WarlordMark from "./WarlordMark";


const Card = ({forceId, rosterId, card, setSummary}) => {

    const [selected, setSelected] = useState(false)
    const [warlord, setWarlord] = useState(false)

    useEffect(() => {
        setSelected(card.selected)
        setWarlord(card.warlord)
    }, [card])

    function addToRoster(e) {
        e.preventDefault()

        doRestCall('/crusade/force/' + forceId + '/roster/' + rosterId + '/card/' + card.id + '/add',
            'put', null, null, (response) => {
                setSummary(response.body.summary)
                setSelected(response.body.selected)
            })
    }

    function removeFromRoster(e) {
        e.preventDefault()

        doRestCall('/crusade/force/' + forceId + '/roster/' + rosterId + '/card/' + card.id + '/remove',
            'put', null, null, (response) => {
                setSummary(response.body.summary)
                setSelected(response.body.selected)
            })
    }

    return (
        <>
            <div>
                <WarlordMark warlord={warlord} />
                {selected && <i className="fa fa-check" onClick={(e) => removeFromRoster(e)}></i> }
                {!selected && <i className="fa-regular fa-square" onClick={(e) => addToRoster(e)}></i> }
                {card?.name} {card?.points} {/*<i className="fa-solid fa-circle-exclamation"></i>*/} <WarlordButton forceId={forceId} rosterId={rosterId}
                                                                                                              cardId={card.id}
                                                                                                              warlord={warlord}
                                                                                                              canBeWarlord={card.canBeWarlord}
                                                                                                              setWarlord={setWarlord} />
                <div className="c-r-manage-equipments" >{card?.equipments}</div>
            </div>
        </>
    );
};

export default Card;

