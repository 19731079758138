import React, {useEffect, useState} from "react";
import {GoogleAnalyticsPageView} from "../GoogleAnalytics";
import {Auth} from "../user/auth/Auth";
import CodexUnits from "./codex/unit/CodexUnits";


const Admin = () => {

    GoogleAnalyticsPageView("/admin", "Admin")

    return (
        <>
            <Auth/>

            <CodexUnits />

        </>
    );
};

export default Admin;

