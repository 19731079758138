import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";

const CloneRemoveButton = ({rosterId, unitId, cloned, reloadRoster}) => {

    function clicked(e) {
        e.preventDefault()

        if (cloned) {
            doRestCall('/roster/unit/clone',
                'delete', null, {
                    rosterId: rosterId,
                    unitId: unitId,
                }, () => reloadRoster())
        }
    }

    return (
        <>
            {cloned && <button className="r-manage-remove-cloned-button" type="link" onClick={(e) => clicked(e)}>Remove</button> }
        </>
    );
};

export default CloneRemoveButton;
