import React, {useEffect, useState} from "react";

import {doRestCall} from "../../AppUtils";


const Pagination = ({pagination, selectedPage}) => {

    const [totalPages, setTotalPages] = useState(pagination?.totalPages)
    const [current, setCurrent] = useState(pagination?.currentPage)

    const [next, setNext] = useState()
    const [prev, setPrev] = useState()

    useEffect(() => {
        setTotalPages(pagination?.totalPages)
        setCurrent(pagination?.currentPage)
    }, [pagination])


    const MAX_PAGES = 10;
    return (
        <>
            {totalPages > MAX_PAGES &&
                <div className="h-pagination">
                    {current > 1 &&
                        <a href='#' onClick={(e) => {
                            e.preventDefault()
                            selectedPage(current - 1)
                        }}>&#60;</a>
                    }
                    {current <= 1 &&
                        <a href='#'>&#60;</a>
                    }

                    <a href='#' onClick={(e) => {
                        e.preventDefault()
                        selectedPage(1)
                    }} className={1 === current ? 'h-pagination-active' : ''}>{1}</a>
                    <a href='#' onClick={(e) => {
                        e.preventDefault()
                        selectedPage(2)
                    }} className={2 === current ? 'h-pagination-active' : ''}>{2}</a>

                    <span>...</span>

                    {((current - 1 < totalPages - 1) && (current - 1 > 2)) &&
                        <a href='#' onClick={(e) => {
                            e.preventDefault()
                            selectedPage(current - 1)
                        }} className={''}>{current - 1}</a>
                    }

                    {((current < totalPages - 1) && (current > 2) ) &&
                        <a href='#' className={'h-pagination-active'}>{current}</a>
                    }

                    {((current + 1 < totalPages - 1) && (current + 1 > 2)) &&
                        <a href='#' onClick={(e) => {
                            e.preventDefault()
                            selectedPage(current + 1)
                        }} className={''}>{current + 1}</a>
                    }

                    <span>...</span>

                    <a href='#' onClick={(e) => {
                        e.preventDefault()
                        selectedPage(totalPages - 1)
                    }} className={(totalPages - 1) === current ? 'h-pagination-active' : ''}>{totalPages - 1}</a>
                    <a href='#' onClick={(e) => {
                        e.preventDefault()
                        selectedPage(totalPages)
                    }} className={(totalPages) === current ? 'h-pagination-active' : ''}>{totalPages}</a>

                    {current < totalPages &&
                        <a href='#' onClick={(e) => {
                            e.preventDefault()
                            selectedPage(current + 1)
                        }}>&#62;</a>
                    }
                    {current >= totalPages &&
                        <a href='#' >&#62;</a>
                    }
                </div>
            }

            {totalPages <= MAX_PAGES &&
                <div className="h-pagination">
                    <a href='#' onClick={(e) => {
                        e.preventDefault()
                        selectedPage(current - 1)
                    }}>&#60;</a>

                    {Array.from({length: totalPages}, (_, i) => i + 1).map((index) =>
                        <a href='#' onClick={(e) => {
                            e.preventDefault()
                            selectedPage(index)
                        }} className={index === current ? 'h-pagination-active' : ''}>{index}</a>
                    )}

                    <a href='#' onClick={(e) => {
                        e.preventDefault()
                        selectedPage(current + 1)
                    }}>&#62;</a>

                </div>
            }
        </>
    )

};

export default Pagination;

