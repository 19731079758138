import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const WarlordButton = ({rosterId, unitId, canBeWarlord, warlord, setWarlord}) => {

    function clicked(e) {
        e.preventDefault()

        if (warlord) {
            doRestCall('/roster/unit/warlord',
                'put', null, {
                    rosterId: rosterId,
                    unitId: unitId,
                    value: false
                }, (response) => setWarlord(response.body.warlord))
        }

        if (!warlord) {
            doRestCall('/roster/unit/warlord',
                'put', null, {
                    rosterId: rosterId,
                    unitId: unitId,
                    value: true
                }, (response) => setWarlord(response.body.warlord))
        }

        setWarlord(warlord);
    }

    return (
        <>
            {canBeWarlord && <button className="r-manage-warlord-button " type="link" onClick={(e) => clicked(e)}>Warlord</button> }
        </>
    );
};

export default WarlordButton;

