import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const CloneButton = ({rosterId, unitId, cloned, reloadRoster}) => {

    function clicked(e) {
        e.preventDefault()

        if (!cloned) {
            doRestCall('/roster/unit/clone',
                'put', null, {
                    rosterId: rosterId,
                    unitId: unitId,
                }, () => reloadRoster())
        }
    }

    return (
        <>
            {!cloned && <button className="r-manage-clone-button" type="link" onClick={(e) => clicked(e)}>Clone</button> }
        </>
    );
};

export default CloneButton;

