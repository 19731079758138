import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {doRestCall} from "../../AppUtils";
import {Auth} from "../../user/auth/Auth";
import Unit from "./components/Unit";
import EditButton from "./components/EditButton";
import "./r-view-styles.css"
import DeleteButton from "./components/DeleteButton";


const RosterView = () => {

    let {rosterId} = useParams();

    const [roster, setRoster] = useState([]);
    const [summary, setSummary] = useState(0)

    const navigate = useNavigate();

    useEffect(() => {
        reloadRoster()
    }, [])

    function navigateBack() {
        navigate("/rosters")
    }

    function reloadRoster() {
        doRestCall('/roster/' + rosterId + '/view', 'get', null, null,
            (response) => setRoster(response.body));
    }

    return (
        <>
            <Auth/>

            <div className="c-f-view-section mt-5">
                <div className="c-r-manage-roster-container">
                    <div className="c-f-view-header mb-5">
                        <h2>{roster?.name}</h2>
                        <div className="r-view-summary">Summary: <p className="r-view-summary-number">{roster?.summary}</p></div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="d-flex mb-2 justify-content-center ">
                                {roster?.units?.characterUnits?.length > 0 &&
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Character</div>
                                        <br/>
                                        {roster?.units?.characterUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit}
                                                                    setSummary={setSummary}
                                                                    reloadRoster={reloadRoster}/><br/></div>)}
                                    </div>}

                                {roster?.units?.battlelineUnits?.length > 0 &&
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Battleline</div>
                                        <br/>
                                        {roster?.units?.battlelineUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit}
                                                                    setSummary={setSummary}
                                                                    reloadRoster={reloadRoster}/><br/></div>)}
                                    </div>}
                                {roster?.units?.vehicleUnits?.length > 0 &&
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Vehicle</div>
                                        <br/>
                                        {roster?.units?.vehicleUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit}
                                                                    setSummary={setSummary}
                                                                    reloadRoster={reloadRoster}/><br/></div>)}

                                    </div>}
                                {roster?.units?.otherUnits?.length > 0 &&
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Other</div>
                                        <br/>
                                        {roster?.units?.otherUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit}
                                                                    setSummary={setSummary}
                                                                    reloadRoster={reloadRoster}/><br/></div>)}
                                    </div>}
                            </div>

                            <div className="row d-flex justify-content-between">
                                <div className="col-auto">
                                    <div className="c-f-view-tlist-btn" onClick={() => navigateBack()}>Back</div>
                                </div>
                                <div className="col-auto">
                                    <EditButton rosterId={rosterId} ownerId={roster.ownerId}/>
                                    <DeleteButton rosterId={rosterId} ownerId={roster.ownerId}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default RosterView;

