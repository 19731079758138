import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../AppUtils";
import {Popover} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

const RosterPointsLimitValidation = ({gameId, rosterId}) => {

    const [settings, setSettings] = useState([])

    const [isPointsLimitSelected, setPointsLimitSelected] = useState(false)
    const [validationResult, setValidationResult] = useState()
    const [validationErrors, setValidationErrors] = useState()

    let limits = ["POINTS_LIMIT_500", "POINTS_LIMIT_750"]

    useEffect(() => {
        if (rosterId !== null && rosterId !== undefined && gameId !== null && gameId !== undefined) {
            doRestCall('/game/' + gameId + '/settings', 'get', null, null,
                (response) => setSettings(response.body))
        }
    }, [gameId, rosterId])

    useEffect(() => {
        if (settings !== null && settings !== undefined) {
            let map = settings.filter(setting => setting.selected === true)
                .map(setting => setting.name);

            setPointsLimitSelected(map.some(limit => limits.includes(limit)))
        }
    }, [settings])

    function onSync(e) {
        e.preventDefault()

        doRestCall('/validate/roster', 'post', null, {
            gameId: gameId,
            rosterId: rosterId
        },
        (response) => onSuccess(response))
    }

    function onSuccess(response) {
        console.log("response", response)
        if (response.valid) {
            console.log("response.valid")

            setValidationResult("true")
        }
        if (!response.valid) {
            console.log("!response.valid")
            setValidationResult("false")
            setValidationErrors(response.errors)
        }
    }


/*
    if (rosterId && isPointsLimitSelected) {
        console.log("validationResult", validationResult)
        return <>
            {validationResult}
            {(validationResult === true) && <i className="fa-solid fa-check"></i> }
            {(validationResult === false) && <i className="fa-solid xmark"></i> }

            <i className="fa-solid fa-rotate" onClick={(e) => onSync(e)}></i>
        </>
    }

    if (!isPointsLimitSelected) {
        return <></>
    }
*/

    useEffect(() => {
        console.log("validationResult", validationResult, validationResult === "false")
    }, [validationResult])

    return <>
        {validationResult === "true" && <i className="fa-solid fa-check"></i>}
        {validationResult === "false" &&
            <Popover content={validationErrors.map(e => e.error).join(", ")} title="Validation Results">
                <i className="fa-solid fa-xmark"></i>
            </Popover>
        }
        <i className="fa-solid fa-rotate" onClick={(e) => onSync(e)}></i>
    </>

}

export default RosterPointsLimitValidation;