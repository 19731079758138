import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Modal, Popover, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";
import {updateBattleTally, updateVictories} from "../../ForceConnector";
import {notificationBattleTallyUpdateSuccess, notificationVictoriesUpdateSuccess} from "../../ForceNotifications";
import {notificationFailure} from "../../../card/CardNotifications";

const BattleTally = ({value, forceId}) => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        doRestCall('/crusade/force/' + forceId + '/tally', 'get', null, null,
            (response) => {
                setItems(response.body.items);
            })
    }, [forceId]);

    function header() {
        return <h4>Games</h4>;
    }

    function content(items) {
        return <div>
            <div>&nbsp;</div>
            <div>
                {items?.map(g => <div>{g.gameName}</div>)}
            </div>
            <div>&nbsp;</div>
        </div>;
    }

    const onBattleTallyUpdate = (e) => {
        updateBattleTally(forceId, e.target.value, notificationBattleTallyUpdateSuccess, notificationFailure)
    }

    return <>
        <Popover content={content(items)} title={header()}>
            <input type="number" min={0} defaultValue={value} disabled={true} onBlur={(e) => {
                onBattleTallyUpdate(e)
            }}/>
        </Popover>
    </>
}

export default BattleTally;