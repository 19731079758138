import React, {useEffect, useState} from "react";
import {Modal, Popover, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";
import {updateExperiencePoints} from "../../CardConnector";
import {notificationExperiencePointsUpdateSuccess, notificationFailure} from "../../CardNotifications";

const ExperiencePoints = ({value, forceId, cardId}) => {

    const [response, setResponse] = useState([]);

    useEffect(() => {
        doRestCall('/crusade/force/' + forceId + '/card/' + cardId + '/experiencepoints', 'get', null, null,
            (response) => {
                setResponse(response.body);
            })
    }, [forceId, cardId]);

    function expHeader() {
        return <h4>Calculation Details</h4>;
    }

    function expDetails(expDetails) {
        return <div>
            <div>&nbsp;</div>
            <div>
                <h6>Games where survived</h6>
            </div>
            <div>
                {expDetails?.survivedGames?.map(g => <div>{g.gameName}</div>)}
            </div>
            <div>&nbsp;</div>
            <div>
                <h6>Games where mark of greatness</h6>
            </div>
            <div>
                {expDetails?.markOfGreatnessGames?.map(g => <div>{g.gameName}</div>)}
            </div>
            <div>&nbsp;</div>
            <div>
                <h6>Games where enemy units destroyed</h6>
            </div>
            <div>
                {expDetails?.enemyUnitsDestroyedGames?.map(g => <div>{g.gameName} {g.value}</div>)}
            </div>
        </div>;
    }

    const onExperiencePointsUpdate = (e) => {
        updateExperiencePoints(forceId, cardId, e.target.value, notificationExperiencePointsUpdateSuccess, notificationFailure)
    }

    return <>
        <div className="c-c-manage-mb-3">
            <div className="row">
                <div className="col-md-4 text-end"><label className="form-label">Experience Points</label></div>
                <div className="col-md-4"><input type="number" placeholder="" min={0} disabled={true}
                                                 defaultValue={value}
                                                 onBlur={(e) => onExperiencePointsUpdate(e)}/>
                </div>
                <div className="col-md-2">
                    <Popover content={expDetails(response)} title={expHeader()}>
                        <i className="fa-solid fa-info"></i>
                    </Popover>
                </div>
            </div>
        </div>
    </>

}

export default ExperiencePoints;