import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../AppUtils";
import RosterPointsLimitValidation from "./RosterPointsLimitValidation";
import CrusadeGameResults from "./CrusadeGameResults";

const CrusadeRoster = ({currentUser, ownerId, playerId, gameId}) => {

    const [rosters, setRosters] = useState([])
    const [selectedRosterId, setSelectedRosterId] = useState(undefined)
    const [selectedRosterName, setSelectedRosterName] = useState("")
    const [locked, setLocked] = useState(false)

    const [settings, setSettings] = useState([])

    const [isCrusadeSelected, setCrusadeSelected] = useState(false)

    useEffect(() => {

        if (gameId !== null && gameId !== undefined) {
            doRestCall('/game/' + gameId + '/settings', 'get', null, null,
                (response) => setSettings(response.body))
        }
    }, [gameId])

    useEffect(() => {
        if (settings !== null && settings !== undefined) {
            setCrusadeSelected(settings.filter(setting => setting.selected === true)
                .map(setting => setting.name)
                .includes('CRUSADE'))
        }
    }, [settings])



    function load() {
        doRestCall('/game/' + gameId + '/user/' + playerId + '/roster', 'get', null, null,
            (response) => {
                setSelectedRosterId(response.body?.rosterId)
                setLocked(response.body?.locked)
                setSelectedRosterName(response.body?.name)
            })
    }

    useEffect(() => {
        if (playerId !== undefined && playerId !== null) {
            load();
        }
    }, [rosters])

    useEffect(() => {
        if (playerId !== undefined && playerId !== null) {
            doRestCall('/crusade/user/' + playerId + '/rosters', 'get', null, null,
                (response) => setRosters(response.body))
        }
    }, [playerId])

    function onChange(e) {
        e.preventDefault()

        if ("" === e.target.value) {
            doRestCall('/game/roster/deassign', 'put', null, {
                gameId: gameId,
                userId: playerId
            })
            setSelectedRosterId(undefined)
        } else {
            doRestCall('/game/roster/assign', 'put', null, {
                gameId: gameId,
                rosterId: e.target.value,
                userId: playerId
            })
            setSelectedRosterId(e.target.value)
        }

    }

    function lock(e) {
        e.preventDefault()

        doRestCall('/game/roster/lock', 'put', null, {
            gameId: gameId,
            userId: playerId,
            rosterId: selectedRosterId
        },
        (response) => load())

    }

    function unlock(e) {
        e.preventDefault()

        doRestCall('/game/roster/unlock', 'put', null, {
            gameId: gameId,
            userId: playerId,
            rosterId: selectedRosterId
        },
        (response) => load())

    }

    if (isCrusadeSelected) {
        return <>
            <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                <div className="col-auto">
                    <div className="g-v-gd-bold">Crusade Roster</div>
                </div>
                <div className="col-auto">
                    <div className="g-v-gd-info">
                        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId) && locked &&
                            <i className="fa-solid fa-lock" style={{color: "white"}} onClick={(e) => unlock(e)}></i>}

                        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId) && !locked &&
                            <i className="fa-solid fa-lock-open" style={{color: "white"}} onClick={(e) => lock(e)}></i>}

                        {!locked && <select onChange={(e) => onChange(e)}>
                            <option value="">None</option>
                            {rosters?.map(roster =>
                                <option value={roster.id} selected={roster.id === selectedRosterId}>{roster.name}</option>)
                            }
                        </select>}

                        {locked && selectedRosterName}

                        <RosterPointsLimitValidation gameId={gameId} rosterId={selectedRosterId} />
                    </div>
                </div>
            </div>

            <CrusadeGameResults gameId={gameId}
                                playerId={playerId}
                                rosterId={selectedRosterId}
            />
        </>
    }

    if (!isCrusadeSelected) {
        return <></>
    }


}

export default CrusadeRoster;