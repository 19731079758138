import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {doRestCall} from "../../AppUtils";
import {Auth} from "../../user/auth/Auth";
import Unit from "./components/Unit";

import "./r-manage-styles.css"

const RosterManage = () => {

    let {rosterId} = useParams();

    const [roster, setRoster] = useState([]);
    const [summary, setSummary] = useState(0)
    const [blink, setBlink] = useState("")
    const navigate = useNavigate();

    useEffect(()=> {
        reloadRoster()
    }, [])

    function navigateBack() {
        navigate("/roster/" + rosterId + "/view");
    }

    function reloadRoster() {
        doRestCall('/roster/' + rosterId + '/manage', 'get', null, null,
            (response) => {
                setRoster(response.body)
                updateSummary(response.body.summary);
            });
    }

    function updateSummary(summary) {
        setSummary(summary)
        setBlink("blink")
        setTimeout(()=> setBlink(""), 300)
    }

    return (
        <>
            <Auth />

            <div className=" mt-5">
                <div className="r-manage-roster-container">
                    <div className=" mb-5">
                        <h2>{roster?.name}</h2>
                        <div className="r-manage-summary">Summary: <p className={"r-manage-summary-number " + blink}>{summary}</p></div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="row d-flex justify-content-between">
                                <div className="col-auto">
                                </div>
                                <div className="col-auto ">
                                </div>
                            </div>
                            <div className="d-flex justify-content-center  mb-2 ">
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Character</div>
                                        <br/>
                                        {roster?.units?.characterUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} /><br/></div> )}
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Battleline</div>
                                        <br/>
                                        {roster?.units?.battlelineUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} /><br/></div>)}
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Vehicle</div>
                                        <br/>
                                        {roster?.units?.vehicleUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} /><br/></div>)}
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Other</div>
                                        <br/>
                                        {roster?.units?.otherUnits
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} /><br/></div>)}
                                    </div>
                            </div>

                            <div className="row d-flex justify-content-between">
                                <div className="col-auto">
                                    <div className="c-f-view-tlist-btn" onClick={() => navigateBack()}>Back</div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default RosterManage;

